import React from 'react';
import {Text, View} from "react-native-web-ui-components";
import {connect} from "react-redux";
import {StyleSheet} from "react-native";
import Button from "../components/Button";
import PaymentProxy from "../components/PaymentProxy";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {Mixpanel} from "../utils/mixpanel";
import {Entypo, Ionicons} from "@expo/vector-icons";
import DiscountCodeScreen from "./DiscountCodeScreen";
import axios from "axios";
import {SubscriptionPaymentPrice} from "../components/subscription/SubscriptionPaymentPrice";

class SubscriptionOrderScreen extends React.Component {
    state = {
        startPayment: false,
        subscriptionType: "20H_LIMITED",
        discountPercentage: 0
    }

    componentDidMount() {
        axios.get(process.env.API2_URL + "/discounts/active")
            .catch(() => {
                this.setState({discountPercentage: 0});
            })
            .then((result) => {
                this.setState({discountPercentage: result?.data?.discountPercentage ?? 0});
            })
    }


    _startPayment(subscriptionType) {
        this.setState({subscriptionType});
        Mixpanel.track("Subscription payment initiated");
        this.setState({
            startPayment: true
        })
    }

    calculateDiscountedPrice = (originalPrice) => {
        const {discountPercentage} = this.state;
        if (discountPercentage > 0) {
            return (originalPrice * (1 - discountPercentage / 100)).toFixed(2);
        }
        return originalPrice.toFixed(2);
    };

    onActivate = () => {
        axios.get(process.env.API2_URL + "/discounts/active")
            .catch(() => {
                this.setState({discountPercentage: 0});
            })
            .then((result) => {
                this.setState({discountPercentage: result?.data?.discountPercentage ?? 0});
            });
    }


    render() {
        const originalPrice1 = 6.99;
        const discountedPrice1 = this.calculateDiscountedPrice(originalPrice1);
        const originalPrice2 = 11.99;
        const discountedPrice2 = this.calculateDiscountedPrice(
            originalPrice2);


        return (
            <React.Fragment>
                <Text style={styles.title}>
                    <h1>
                        Kuupaketiga liitumine
                    </h1>
                </Text>

                <Text style={styles.subTitle}>
                    Vali sobiv pakett ja alusta raamatute kuulamist alates 6.99€ eest kuus.
                </Text>

                <View style={styles.container}>
                    <View style={styles.infoBox}>

                        <View style={styles.cardTitleRow}>
                            <Ionicons name="time-outline" size={48} color="#0000f0"/>
                            <Text style={styles.cardTitle}>Paindlik pakett</Text>
                        </View>

                        <Text style={styles.cardText}>
                            <Entypo name="check" size={8} color="black"/>{" "}
                            <SubscriptionPaymentPrice fullPrice={originalPrice1} discountPrice={discountedPrice1}/>
                            {"\n\n"}
                            <Entypo name="check" size={8} color="black"/> Isiklik konto{"\n\n"}
                            <Entypo name="check" size={8} color="black"/> Kuula 20 tundi kuus{"\n\n"}
                            <Entypo name="check" size={8} color="black"/> Tühista etteteatamiseta
                        </Text>

                        <Button type="dark" onPress={() => this._startPayment("20H_LIMITED")}>
                            Liitu paketiga
                        </Button>
                    </View>
                    <View style={styles.infoBox}>
                        <View style={styles.cardTitleRow}>
                            <Ionicons name="infinite-outline" size={48} color="#0000f0"/>
                            <Text style={styles.cardTitle}>Piiramatu pakett</Text>
                        </View>
                        <Text style={styles.cardText}>
                            <Entypo name="check" size={8} color="black"/>{" "}
                            <SubscriptionPaymentPrice fullPrice={originalPrice2} discountPrice={discountedPrice2}/>
                            {"\n\n"}
                            <Entypo name="check" size={8} color="black"/> Isiklik konto
                            <br/><br/>
                            <Entypo name="check" size={8} color="black"/> Kuula
                            piiramatult
                            <br/><br/>
                            <Entypo name="check" size={8} color="black"/> Tühista
                            etteteatamiseta
                            <br/><br/>
                        </Text>
                        <Button type="dark" onPress={() => this._startPayment("UNLIMITED")}>
                            Liitu paketiga
                        </Button>
                    </View>
                </View>
                <View style={styles.negativeMargin}>
                    <DiscountCodeScreen onActivate={this.onActivate}/>
                </View>
                <PaymentProxy start={this.state.startPayment} user={this.props.user}
                              subscriptionType={this.state.subscriptionType}/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (rootState) => {
    return {
        user: rootState.user,
    };
}

export default compose(
    withRouter,
    connect(mapStateToProps, null)
)(SubscriptionOrderScreen);


const styles = StyleSheet.create({
    cardTitle: {
        fontFamily: "Aino-Headline",
        fontSize: 24,
        display: "flex",
        justifyContent: "center",
    },
    cardTitleRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginTop: 8,
        alignItems: "center",
        gap: 14,
        marginBottom: 8
    },
    negativeMargin: {
        marginTop: -20,
    },
    textLink: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecorationLine: "underline",
        fontSize: 20,
        fontWeight: "500",
        marginBottom: 15,
    },
    title: {
        margin: 8,
        textAlign: "center",
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1.5em',
        lineHeight: 36
    },
    subTitle: {
        margin: 8,
        fontSize: '1.2em',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        flexWrap: "wrap",
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    infoBox: {
        minHeight: 300,
        borderWidth: '0.125em',
        margin: '3.25em',
        paddingBottom: '1.5em',
        paddingHorizontal: '2.5em',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    callToActionContainer: {
        paddingHorizontal: '1.5em',
        paddingBottom: '0.625em',
    },
    cardText: {
        fontSize: '1em',
    },
    optionImage: {
        width: '200px',
        height: 'auto',
        paddingTop: '1.5em',
        paddingBottom: '0.5em'
    },
})
