import React, {useEffect, useRef, useState} from "react";
import {Loading, Text, View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import AudioPlayer from "../components/AudioPlayer";
import {PLAYER_CLOSE} from "../redux/actions";
import {selectDlUrl} from "../redux/config/configSelectors";
import {useLibrary} from "../modules/Library";
import Modal from "../components/Modal";
import Button from "../components/Button";
import {addBookToCart} from "../redux/cart/cartActions";
import {useHistory} from "../routing.web";

function AudioPlayerScreen(props) {
    const id = parseInt(props.player.bookId)
    const [loading, setLoading] = useState(true)
    const books = useSelector(state => state.books);
    const {library, isLoading, mutateLibrary} = useLibrary();
    const user = useSelector(state => state.user);
    const [book, setBook] = useState();
    const [showSubscriptionUsedWarning, setShowSubscriptionUsedWarning] = useState();
    const dlUrl = useSelector(selectDlUrl);
    const links = useRef([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        volume = 1,
        rate = 1,
    } = props.player
    const {
        index = 0,
        position = 0,
    } = (props.player.positions && props.player.positions[book?.id]) ? props.player.positions[book?.id] : {}
    const savedProps = {volume, rate, index, position}

    useEffect(() => {
        if (!isLoading) {
            setBook(library.find(b => b.id === id) || books.find(b => b.id === id));
        }
    }, [id, user, isLoading]);

    useEffect(() => {
        if (!book || !loading) {
            return;
        }
        axios.get(process.env.API2_URL + "/books/" + book.id + "/files")
            .then(res => {
                links.current = res.data.files.map(path => `${dlUrl}/books/${book.id}/${path}?ticket=${res.data.ticket}`);
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 402) {
                    setShowSubscriptionUsedWarning(true);
                    setLoading(false);
                } else {
                    dispatch({
                        type: PLAYER_CLOSE,
                    });
                    throw (error);
                }
            })
    }, [book]);

    const onClose = () => {
        dispatch({
            type: PLAYER_CLOSE,
        });
    }

    const addToCart = () => {
        dispatch(addBookToCart(id));
        onClose();
        history.push("/ostukorv");
    }

    const changeSubscription = () => {
        onClose();
        history.push("/paketivahetus/web");
    }

    if (showSubscriptionUsedWarning) {
        return (
            <Modal style={{margin: 0}} isVisible={true} onBackdropPress={() => {
                dispatch({type: PLAYER_CLOSE});
            }}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Paketi maht on täis</Text>
                        <View style={styles.buttonRow}>
                            <View style={styles.buttonRowLeftSide}>
                                <Button type="dark" onPress={changeSubscription} style={styles.button}>
                                    <Text>Liitun piiramatu paketiga</Text>
                                </Button>
                                <Button type="dark" onPress={addToCart} style={styles.button}>
                                    <Text>Lisan raamatu ostukorvi</Text>
                                </Button>
                            </View>
                            <Button type="dark" onPress={onClose} style={styles.button}>
                                <Text>Sulge</Text>
                            </Button>
                        </View>
                    </View>
                </View>
            </Modal>
        );
    }

    return (loading || isLoading) ? <Loading/> : (
        <View style={styles.container}>
            <AudioPlayer
                apiToken={user ? user.apiToken : null}
                headers={{Authorization: axios.defaults.headers.common['Authorization']}}
                links={links.current}
                author={book.authorFirstName + " " + book.authorLastName}
                title={book.title}
                description={book.description}
                imageUri={book.image}
                bookId={book.id}
                {...savedProps}
            />
        </View>
    )
}

export default AudioPlayerScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    modalContent: {
        maxWidth: 550,
        width: "calc(100% - 10px)",
        backgroundColor: "white",
        padding: 22,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        borderColor: "rgba(0, 0, 0, 0.1)",
    },
    modalTitle: {
        margin: 8,
        fontSize:
            '1em',
        textAlign:
            'center',
        display:
            'flex',
        flexDirection:
            'column',
    },
    buttonRowLeftSide: {
        display: "flex",
        marginRight: 8,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
    },
    buttonRowRightSide: {
        minWidth: 375,
        display: "flex",
    },
    button: {
        margin: 4,
    },
    buttonRow: {
        width: "100%",
        padding: 4,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
    },
})
