import {combineReducers} from "redux";
import blocksReducer from "./blocksReducer";
import booksReducer from "./booksReducer";
import categoriesReducer from "./categoriesReducer";
import userDataReducer from "./userDataReducer";
import libraryReducer from "./libraryReducer";
import libraryLoadingReducer from "./libraryLoadingReducer";
import pagesReducer from "./pagesReducer";
import playerReducer from "./playerReducer";
import menuOpenReducer from "./menuOpenReducer";
import {cartReducer} from "../cart/cartReducer";
import {configReducer} from "../config/configReducer";
import gdprConsentReducer from "./gdprConsentReducer";
import searchQueryReducer from "./searchQueryReducer";
import lastPathReducer from "./lastPathReducer";
import announcementReducer from "./announcementReducer";
import discountCodeReducer from "./discountCodeReducer";

export default combineReducers({
    blocks: blocksReducer,
    announcement: announcementReducer,
    books: booksReducer,
    categories: categoriesReducer,
    user: userDataReducer,
    discountCode: discountCodeReducer,
    library: libraryReducer,
    libraryLoading: libraryLoadingReducer,
    pages: pagesReducer,
    player: playerReducer,
    isMenuOpen: menuOpenReducer,
    cart: cartReducer,
    config: configReducer,
    gdprConsent: gdprConsentReducer,
    searchQuery: searchQueryReducer,
    lastPath: lastPathReducer,

    /**
     * @deprecated
     */
    products: booksReducer,
})

export const selectBook = bookId => rootState => rootState.products.find(product => product.id === bookId);

export const selectUser = rootState => rootState.user;

export const selectUserSubscribed = rootState => {

    // TODO - this is no longer the case!!!!
    // There is a 180 day grace period after subscriptions end where tokens and subscription books are still usable
    const date180DaysAgo = new Date();
    date180DaysAgo.setDate(date180DaysAgo.getDate() - 180);

    return new Date(rootState.user?.subscriptionExpiresAt ?? "2020-01-01") > date180DaysAgo;
};
export const selectUserSubscriptionDiscountActive = rootState => new Date(rootState.user?.subscriptionExpiresAt ?? "2020-01-01") > new Date();
export const selectDiscountCode = rootState => rootState.discountCode;
export const selectDiscountCodePercentage = rootState => rootState.discountCode?.discountPercentage ?? 0;
