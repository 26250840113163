import React from "react";
import {Column, Image, Row, Text, View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native";
import Link from "./Link";

export default function Footer() {
    return (
        <View style={styles.container}>
            <Row style={styles.content}>
                <Column style={styles.column}>
                    <Text numberOfLines={1} style={styles.title}>Kuulamiselamus</Text>
                    <View style={styles.links}>
                        <Link
                            to="/registreerumine"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Kuula järele!
                        </Link>
                        <Link
                            to="/raamatud"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Raamatud
                        </Link>
                        <Link
                            to="/kingitus"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Kingitus
                        </Link>
                        <Link
                            to="/meist"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Meist
                        </Link>
                        <Link
                            to="/paketi-muudatus"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Paketi muudatus
                        </Link>
                    </View>
                </Column>
                <Column style={styles.column}>
                    <Text numberOfLines={1} style={styles.title}>Informatsioon</Text>
                    <View style={styles.links}>
                        <Link
                            to="/kasutustingimused"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Kasutustingimused
                        </Link>
                        <Link
                            to="/privaatsuspoliitika"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Privaatsuspoliitika
                        </Link>
                        <Link
                            to="/korduma-kippuvad-kusimused"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Korduma kippuvad küsimused
                        </Link>
                        <Link
                            blank external
                            to="mailto:info@digiread.ee"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Võta ühendust
                        </Link>
                    </View>
                </Column>
                <Column style={styles.column}>
                    <Text numberOfLines={1} style={styles.title}>Jälgi meid</Text>
                    <View style={styles.links}>
                        <Link
                            blank external
                            to="https://www.facebook.com/Digiread-105221577889282/"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Facebook
                        </Link>
                        <Link
                            blank external
                            to="https://www.instagram.com/digiread.ee"
                            style={styles.link}
                            textStyle={styles.linkText}
                            textHoverStyle={styles.linkTextHover}
                        >
                            Instagram
                        </Link>
                        <Link
                          blank external
                          to="https://play.google.com/store/apps/details?id=ee.digiread.app"
                          style={[styles.link, {margin: -10}]}
                        >
                            <Image
                              style={{width: 180, height: 63}}
                              source={{uri: require('../assets/google-play-badge.png')}}
                              alt="google-play-badge"
                            />
                        </Link>
                        <Link
                            blank external
                            to="https://apps.apple.com/us/app/digiread/id1570619175"
                            style={[styles.link, {marginTop: "2vh"}]}
                        >
                            <Image
                                style={{width: 156, height: 40}}
                                source={{uri: require('../assets/app-store-badge.png')}}
                                alt="app-store-badge"
                            />
                        </Link>
                    </View>
                </Column>
            </Row>
            <Row style={styles.copyrightRow}>
                <Column>
                    <View style={styles.copyrightContainer}>
                        <Text numberOfLines={1} style={styles.copyrightContent}>
                            OÜ Digiread
                        </Text>
                    </View>
                </Column>
                <Column>
                    <View style={styles.copyrightContainer}>
                        <Text numberOfLines={1} style={styles.copyrightContent}>
                            Laki tn 26, Tallinn, 12915
                        </Text>
                    </View>
                </Column>
                <Column>
                    <View style={styles.copyrightContainer}>
                        <Text numberOfLines={1} style={styles.copyrightContent}>
                            info[ät]digiread.ee
                        </Text>
                    </View>
                </Column>
                <Column>
                    <View style={styles.copyrightContainer}>
                        <Text numberOfLines={1} style={styles.copyrightContent}>
                            +372 5191 8192
                        </Text>
                    </View>
                </Column>
                <Column>
                    <View style={styles.copyrightContainer}>
                        <Text numberOfLines={1} style={styles.copyrightContent}>
                            © 2025
                        </Text>
                    </View>
                </Column>
            </Row>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        backgroundColor: 'rgb(37,37,37)',
        marginTop: 20,
    },
    image: {
        flex: 1,
        justifyContent: "center"
    },
    content: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        paddingHorizontal: '5vw',
        paddingTop: '5vh',
        paddingBottom: 50,
    },
    column: {
        flexGrow: 1,
        flexShrink: 1,
        margin: 20,
    },
    title: {
        fontSize: 24,
        lineHeight: 28,
        fontFamily: "Aino-Headline",
        textTransform: 'uppercase',
        color: 'white',
    },
    links: {

    },
    link: {

    },
    linkText: {
        fontSize: 20,
        lineHeight: 24,
        fontFamily: 'MyriadPro-Regular',
        color: 'white',
        marginVertical: '2vh',
    },
    linkTextHover: {
        color: 'rgb(200, 200, 200)',
    },
    copyrightRow: {
        justifyContent: 'center',
        paddingHorizontal: '5vw',
        paddingBottom: 50,
    },
    copyrightContainer: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginHorizontal: 20,
    },
    copyrightContent: {
        color: 'white',
        fontFamily: 'Aino-Regular',
        fontSize: 12,
        lineHeight: 16,
    },
})
