import {Text} from "react-native-web-ui-components";
import React from "react";

export const SubscriptionPaymentPrice = (props) => {
    const fullPrice = props.fullPrice.toFixed(2);
    const discountPrice = props.discountPrice;
    return discountPrice !== fullPrice ? (
        <>
            <Text style={{textDecorationLine: 'line-through', color: 'gray'}}>
                {fullPrice}€
            </Text>
            {" "}
            <Text style={{fontWeight: 'bold', color: 'green'}}>
                {discountPrice}€
            </Text>
            /kuu
        </>
    ) : (
        `${fullPrice}€/kuu`
    )
}