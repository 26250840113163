import React, {useState} from "react";
import {StyleSheet} from "react-native";
import {connect, useSelector} from "react-redux";
import {Text, TextInput, View} from "react-native-web-ui-components";
import Button from "../components/Button";
import {useDimensions} from "react-native-responsive-ui/src";
import axios from "axios";
import {showMessage} from "react-native-flash-message";
import {selectUser} from "../redux/reducers";
import {useFormStyles} from "../components/forms/styles";

const DiscountCodeScreen = props => {
    const [discountCode, setDiscountCode] = useState();
    const styles = useStyles();
    const details = useSelector(selectUser);
    const formStyles = useFormStyles();

    const activate = () => {
        if (discountCode) {
            axios.post(process.env.API2_URL + "/discounts/" + discountCode + "/activate",).then(() => {
                setDiscountCode(undefined);
                showMessage({
                    message: 'Sooduskood on aktiveeritud',
                    type: 'success',
                });

                if (props.onActivate !== undefined) {
                    props.onActivate();
                }

            }).catch(e => {
                if (e.response) {
                    switch (e.response.status) {
                        case 404:
                            showMessage({
                                message: 'Sooduskoodi ei leitud või see ei ole enam aktiivne',
                                type: 'danger',
                            });
                            break;
                        default:
                            showMessage({
                                message: 'Sooduskoodi aktiveerimine ebaõnnestus',
                                type: 'danger',
                            });
                    }
                }
            });
        }
    }

    return (
        <View style={styles.container}>
            <Text style={styles.sectionTitle}>Aktiveeri paketi sooduskood</Text>

            <View style={styles.codeActivationContainer}>
                <View style={styles.codeActivationText}>
                    <Text style={styles.codeActivationLabel}>Soovid sooduskoodi aktiveerida?</Text>
                    <Text style={styles.codeActivationDescription}>{details
                        ? "Sisesta oma sooduskood siia, et see aktiveerida."
                        : " Sooduskoodi aktiveerimiseks pead olema sisse logitud."}
                    </Text>
                </View>
                <View style={styles.codeActivationForm}>
                    <View style={formStyles.inputContainer}>
                        <TextInput
                            autoFocus
                            style={formStyles.input}
                            focusStyle={[formStyles.input, formStyles.inputFocus]}
                            placeholder="Sooduskood"
                            onChangeText={code => setDiscountCode(code)}
                            value={discountCode}
                            blurOnSubmit={false}
                            disabled={!details}
                        />
                    </View>
                    <Button
                        type="dark"
                        style={styles.codeActivationFormButton}
                        hoverStyle={styles.buttonHover}
                        textStyle={styles.buttonText}
                        textHoverStyle={styles.buttonTextHover}
                        onPress={activate}
                        disabled={!details}
                    >
                        Aktiveeri
                    </Button>
                </View>
            </View>
        </View>
    );
}

const mapStateToProps = ({pages}) => ({pages})

export default connect(mapStateToProps)(DiscountCodeScreen)

const useStyles = () => {
    const {width} = useDimensions()
    const isWide = width > 800
    return StyleSheet.create({
        container: {
            flex: 1,
            // padding: 50,
        },
        purchaseContainer: {
            display: "flex",
            flexDirection: isWide ? "row" : "column",
            justifyContent: "center",
            alignContent: "center",
        },
        sectionTitle: {
            marginTop: 20,
            fontFamily: "Aino-Headline",
            fontSize: 24,
            display: "flex",
            justifyContent: "center"
        },
        codeActivationContainer: {
            margin: "auto",
            display: "flex",
            flexDirection: isWide ? "row" : "column",
            justifyContent: "space-evenly",
            alignContent: "center",
            backgroundColor: "rgba(241,241,241,0.15)",
            borderStyle: "solid",
            borderColor: "#6161FF",
            borderWidth: 1,
            borderRadius: 8,
            padding: 30,
            marginTop: 10,
            maxWidth: 1440,
            width: isWide ? "auto" : "100%"
        },
        codeActivationText: {
            marginTop: 20,
            marginRight: 20,
        },
        codeActivationFormButton: {
            width: "100%",
            marginTop: "2.5px"
        },
        codeActivationLabel: {
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 8,
            width: "auto"
        },
        codeActivationDescription: {
            fontSize: 14,
            marginBottom: 8,
            width: "auto"
        },
        codeActivationForm: {
            display: "flex",
            flexDirection: isWide ? "row" : "column",
            alignContent: "center",
            justifyContent: "center",
            marginTop: 20
        },
        purchaseOption: {
            width: isWide ? "20%" : "100%",
            maxWidth: isWide ? 440 : undefined,
            minWidth: 220,
            padding: 30,
            marginHorizontal: isWide ? 25 : 0,
            marginVertical: isWide ? 10 : 20,
            borderStyle: "solid",
            borderColor: "black",
            borderWidth: 1,
            textAlign: "center",
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(241,241,241,0.15)"
        },
        optionDurationLabel: {
            color: "#6161FF",
            fontSize: 24,
            marginBottom: 4,
            fontWeight: "bold"
        },
        optionLabel: {
            fontSize: 18,
            marginBottom: 8
        },
        priceLabel: {
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 8
        },
        centeredContainer: {
            justifyContent: "center",
            alignItems: "center",
        },
    })
};


