import {useSelector} from "react-redux";
import {selectSubscriberDiscount} from "../redux/config/configSelectors";
import {selectDiscountCodePercentage, selectUserSubscriptionDiscountActive} from "../redux/reducers";

export const useSubscriberDiscountCalculator = () => {
    const subscriberDiscount = useSelector(selectSubscriberDiscount);
    return (fullPrice) => (fullPrice * (1 - subscriberDiscount / 100)).toFixed(2);
};

export const useDiscountCodeDiscountCalculator = () => {
    const discountCode = useSelector(selectDiscountCodePercentage);
    return (initialPrice) => (initialPrice * (1 - discountCode / 100)).toFixed(2);
};
