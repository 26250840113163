import React, {useState} from "react";
import {FlatList, StyleSheet, View} from "react-native";
import Text from "../components/Text";
import ScreenTitle from "../components/ScreenTitle";
import {useDispatch, useSelector} from "react-redux";
import {selectCartBooks} from "../redux/cart/cartSelectors";
import {Image} from "react-native-web-ui-components";
import Button from "../components/Button";
import {removeBooksFromCart} from "../redux/cart/cartActions";
import {useDimensions} from "react-native-responsive-ui/src";
import {selectDiscountCodePercentage, selectUserSubscriptionDiscountActive} from "../redux/reducers";
import {
    useDiscountCodeDiscountCalculator,
    useSubscriberDiscountCalculator
} from "../hooks/useSubscriberDiscountCalculator";
import {Mixpanel} from "../utils/mixpanel";
import {TextInput} from "../components/forms";
import axios from "axios";
import {setDiscountCode} from "../redux/actions";

export default () => {
    const cartContents = useSelector(selectCartBooks);

    return (
        <View style={baseStyles.container}>
            <ScreenTitle>Ostukorv</ScreenTitle>
            <View style={baseStyles.listContainer}>
                {cartContents.length === 0 ? <Text>Ostukorv on tühi</Text> : <Cart cartContents={cartContents}/>}
            </View>
        </View>
    );
}

const Cart = ({cartContents}) => {
    const {width} = useDimensions();
    const calculateDiscountPrice = useSubscriberDiscountCalculator();
    const calculateDiscountCodePrice = useDiscountCodeDiscountCalculator();
    const isSubscriptionDiscountActive = useSelector(selectUserSubscriptionDiscountActive);
    const discountCodePercentage = useSelector(selectDiscountCodePercentage);
    const [discountCodeInputValue, setDiscountCodeInputValue] = useState();
    const isWide = width > 799;
    const dispatch = useDispatch();

    const styles = isWide ? baseStyles : narrowStyles;

    const fullPrice = cartContents.reduce((acc, cartItem) => acc + Number.parseFloat(cartItem.price), 0);
    const subscriberDiscountPrice = calculateDiscountPrice(fullPrice);
    const discountCodeDiscountPrice = calculateDiscountCodePrice(isSubscriptionDiscountActive ? subscriberDiscountPrice : fullPrice);

    const checkDiscountCode = () => {
        if (!discountCodeInputValue) return
        axios.get(process.env.API2_URL + '/discounts/' + discountCodeInputValue).then(res => {
            dispatch(setDiscountCode(res.data));
        })
    }

    return (
        <>
            <FlatList data={cartContents} extraData={styles}
                      renderItem={({item}) => <CartItem item={item} styles={styles}
                                                        isUserSubscribed={isSubscriptionDiscountActive}/>}/>
            <View style={styles.discountCodeRow}>
                <Text style={{margin: 8}}>Lisa sooduskood:</Text>
                <TextInput
                    placeholder="Sooduskood"
                    autoFocus
                    onChangeText={name => setDiscountCodeInputValue(name)}
                    blurOnSubmit={false}
                />
                <View style={styles.discountCodeButton}>
                    <Button textStyle={styles.discountCodeButtonText} type={"dark"}
                            onPress={checkDiscountCode}>Lisa</Button>
                </View>
            </View>
            <View style={styles.footer}>
                <View style={styles.total}>
                    <Text
                        style={[styles.totalText, (isSubscriptionDiscountActive || discountCodePercentage > 0) && styles.strikethroughPrice]}>
                        Tavakasutaja hind: {fullPrice.toFixed(2)}€
                    </Text>
                    <Text
                        style={[styles.totalText, (!isSubscriptionDiscountActive || discountCodePercentage > 0) && styles.strikethroughPrice]}>
                        Paketikasutaja soodushind: {subscriberDiscountPrice}€
                    </Text>
                    {discountCodePercentage > 0 && <Text style={[styles.totalText]}>
                        Hind sooduskoodiga: {discountCodeDiscountPrice}€
                    </Text>}
                </View>
                <View style={styles.payButton}>
                    <Button textStyle={styles.payButtonText} type={"dark"} to={"/maksmine"}>Maksma</Button>
                </View>
            </View>
        </>
    );
};

const CartItem = ({item, styles, isUserSubscribed}) => {
    const dispatch = useDispatch();
    const calculateDiscountPrice = useSubscriberDiscountCalculator();
    const label = item.author + " - " + item.title;

    const handleRemoveFromCart = () => {
        dispatch(removeBooksFromCart([item.id]));
        Mixpanel.track("Book removed from cart", {"bookId": item.id, "author": item.author, "title": item.title});
    }

    return <View style={styles.listItem}>
        <Image fixed style={styles.thumbnail} source={{uri: item.image}} alt={label}/>
        <Text style={styles.label}>{label}</Text>
        <View>
            {!isUserSubscribed ?
                <Text style={styles.price}>{item.price + "€"}</Text>
                :
                <>
                    <Text
                        style={[styles.price, styles.strikethroughPrice]}>{" " + item.price + "€ "}</Text>
                    <Text style={styles.price}>{" " + calculateDiscountPrice(item.price) + "€ "}</Text>
                </>
            }
        </View>
        <Button type={"dark"} onPress={handleRemoveFromCart}>Eemalda</Button>
    </View>;
};

const baseStylesObject = {
    container: {
        flex: 1,
        padding: 50,
    },
    listContainer: {
        paddingTop: 20,
    },
    listItem: {
        borderBottomWidth: 1,
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        padding: 10,
    },
    thumbnail: {
        width: 90,
        height: 130,
    },
    label: {
        flex: 1,
        marginLeft: 20,
    },
    price: {
        width: 100,
        textAlign: "right",
        marginRight: 20,
    },
    footer: {
        flexDirection: "row",
        width: "100%",
        marginTop: 30,
    },
    discountCodeRow: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        marginTop: 30,
    },
    total: {
        margin: 10,
        height: 30,
        flex: 1
    },
    totalText: {
        fontSize: 26,
    },
    strikethroughPrice: {
        textDecorationLine: "line-through",
        color: "#535353"
    },
    payButton: {
        fontSize: 26,
    },
    discountCodeButton: {
        fontSize: 16,
        marginLeft: 8
    },
    discountCodeButtonText: {
        fontSize: 16,
        padding: 3,
    },
    payButtonText: {
        fontSize: 26,
        padding: 12,
    },
};
const baseStyles = StyleSheet.create(baseStylesObject);

const narrowStyles = StyleSheet.create({
    ...baseStylesObject,
    listItem: {
        ...baseStylesObject.listItem,
        flexDirection: "column",
    },
    label: {
        ...baseStylesObject.label,
        textAlign: "center",
        marginLeft: 0,
        marginVertical: 10,
    },
    price: {
        ...baseStylesObject.price,
        textAlign: "center",
        marginBottom: 10,
        marginRight: 0,
    },
    footer: {
        ...baseStylesObject.footer,
        flexDirection: "column",
        marginTop: 2
    },
    total: {
        ...baseStylesObject.total,
        alignSelf: "center"
    },
    payButton: {
        ...baseStylesObject.payButton,
        marginTop: 15,
        alignSelf: "center"
    },
    discountCodeRow: {
        ...baseStylesObject.discountCodeRow,
        flexDirection: "column",
    },
    discountCodeButton: {
        ...baseStylesObject.discountCodeButton,
        marginTop: 8
    }

});

